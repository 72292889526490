import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Mobile app solutions improve the productivity of your employees by providing them with information or on-site information on demand and at all times by connected consumers on your smartphone services.`}</p>
    </PageDescription>
    <Row className="post-video-row" mdxType="Row">
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/mobiledev.svg",
            "alt": "Mobile Development"
          }}></img>
        </div>
      </Column>
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <br />
        <p>{`New business opportunities such as customized customer service and simpler internal communication have led to the development of smartphones and tablet computing.`}</p>
        <p>{`Wizbord is an innovator in the development of mobile apps and ensures a number of advantages: `}</p>
        <ul>
          <li parentName="ul">{`consistent experience in different device formats, `}</li>
          <li parentName="ul">{`easier user interfaces, `}</li>
          <li parentName="ul">{`reduced training and support costs,
networked real-time information access. `}</li>
        </ul>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      